import React from "react"
import { Link } from "gatsby"
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton } from 'react-twitter-embed';

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import "../../components/fontawesome.css"
import LeftMenu from './leftMenu'

const GuidelinesTimeline = () => (
  <Layout>
    <SEO title="National Student Essay Competition" />

    <div className="subpageContainer">

      <LeftMenu active="guidelines-and-timeline" />
      <div className="body">
        <h3 style={{marginTop: '55px'}}>NATIONAL STUDENT ESSAY COMPETITION: “WHY A FREE PRESS MATTERS IN A DEMOCRACY”</h3>
        <h1> Guidelines &amp; Timeline</h1>
        
        <h3>THREE CATEGORIES</h3>
          <ol>
            <li>Grades 6-8</li>
            <li>Grades 9-12</li>
            <li>University/College</li>
          </ol>

        <h3>CALL-FOR-ENTRIES</h3>
          <p>Beginning Monday March 2, 2020<br /><br />
          <strong>Deadline for submissions:</strong> Friday, April 24, 2020<br /><br />
          National winners announced mid-July 2020</p>

        <h3>WEBSITE</h3>
          <p><a href="https://mklpr.com/national-student-essay-competition" alt="Student Essay Competition Website">https://mklpr.com/national-student-essay-competition</a></p>

        <h3>SOCIAL MEDIA</h3>
        <p><a href="https://twitter.com/search?q=%23StudentsForPressFreedom&src=typed_query">#StudentsForPressFreedom</a><br />
        <a href="https://twitter.com/freepressessays">@freepressessays</a>
        </p>

        <h3>EMAIL</h3>
          <p>
            Questions:  <a href="mailto:studentessays@mklpr.com" alt="mail Student Essays">studentessays@mklpr.com</a><br />
            Essay submissions:  <a href="mailto:studentessays@mklpr.com" alt="mail Student Essays">studentessays@mklpr.com</a>
          </p>

        <h3>ENTRY REQUIREMENTS</h3>
          <p><strong>Topic:</strong> “Why a free press matters in a democracy”<br /><br />
          <strong>Deadline:</strong> Friday, April 24, 2020. <br /><br />
          Email a Word document of 500 words maximum typed and double-spaced, to <a href="mailto:studentessays@mklpr.com">studentessays@mklpr.com</a>. All entries must be original and must include student’s name, phone number, email address, mailing address, name and department of the school and grade or university/college in which the student is currently enrolled, and the name of your local news outlet(s). Essays may be submitted by midnight on Friday, April 24, 2020.</p>

        <h3>JUDGING CRITERIA</h3>
          <p>Originality and thoughtfulness in addressing the topic of “Why a free press matters in a democracy.”</p>

        <h3>NATIONAL PRIZES</h3>
          <p>The winner in each category (grades six-eight; grades nine-twelve; and grades university/college) will each receive a $5,000 check from the Boston Globe Foundation.</p>
          <p>The winning essayist with the highest ranking among the three categories will also receive a full four-year scholarship, current total value of $152,000, to Westminster College in Salt Lake City.</p>
          <p>The winner of the popular vote will receive a check for $1,000 from MKL Public Relations.</p>
          <p>Prizes will be awarded November 7, 2020 at the McCarthey Family Foundation Lecture Series: In Praise of Independent Journalism.</p>

      </div>
    </div>
  </Layout>
)

export default GuidelinesTimeline
